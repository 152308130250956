import { HorizontalPostCard } from '@/components/Shared/PostCard';
import React from 'react';
import { TimeWeather } from '.';
import { IPostWrapper } from '@/interfaces/wrapper';

const HomeTopSectionR = ({ data }: IPostWrapper) => {
  return (
    <div className="flex flex-col justify-between h-full gap-4 lg:gap-0 justify-between">
      <TimeWeather />

      {data
        ?.slice(0, 4)
        ?.map((item, index) => (
          <HorizontalPostCard key={index} {...item} isOnTopSection />
        ))}
    </div>
  );
};

export default HomeTopSectionR;
